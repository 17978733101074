var render = function render(){
  var _vm$$auth$company, _vm$$auth$company$set;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report-page",
    class: {
      'report-page--landscape': _vm.landscape
    }
  }, [_vm.title ? _c('div', {
    staticClass: "report-bg"
  }) : _vm._e(), !_vm.noHeader ? _c('div', {
    staticClass: "report-header"
  }, [_c('div', {
    staticClass: "report-logo report-logo--left"
  }, [_c('img', {
    attrs: {
      "src": _vm.$auth.company.settings.logoUrl,
      "alt": "Company Logo"
    }
  })]), (_vm$$auth$company = _vm.$auth.company) !== null && _vm$$auth$company !== void 0 && (_vm$$auth$company$set = _vm$$auth$company.settings) !== null && _vm$$auth$company$set !== void 0 && _vm$$auth$company$set.logoUrl ? _c('div', {
    staticClass: "report-logo report-logo--right"
  }, [_c('Logo')], 1) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "report-body",
    class: {
      'report-body--no-header': _vm.noHeader,
      'report-body--no-footer': _vm.noFooter
    }
  }, [_vm.title ? _c('div', {
    staticClass: "report-heading"
  }, [_c('div', [_c('h3', {
    staticClass: "text-primary mb-1"
  }, [_vm._v(_vm._s(_vm.subTitle))]), _c('h1', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.title))]), _c('h5', [_vm._v(_vm._s(_vm.description))]), _c('div', {
    staticClass: "report-title--content"
  }, [_vm._t("title")], 2)])]) : _vm._e(), _vm._t("default")], 2), !_vm.noFooter && !_vm.title ? _c('div', {
    staticClass: "report-footer"
  }, [_c('div', {
    staticClass: "report-footer--inner"
  }, [_c('div', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('div', {
    staticClass: "flex-grow-1 text-center"
  }, [!_vm.noCopyright ? _c('span', [_vm._v("© " + _vm._s(_vm.currentYear) + " Etainabl Limited | 12-16 Addiscombe Rd, Croydon, CR0 1XT")]) : _vm._e()]), _c('div', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.currentPage) + " / " + _vm._s(_vm.pages().length))])])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }